import React from 'react';
import { ShieldCheckIcon, RocketLaunchIcon, UserGroupIcon } from '@heroicons/react/24/outline';

const values = [
  {
    name: 'Independent Innovation',
    description: 'As an independent third-party integration provider, we specialize in developing cutting-edge solutions for modern advertising platforms. Our expertise allows us to create powerful tools that maximize the potential of digital advertising ecosystems.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Commitment to Growth',
    description: 'We are dedicated to helping businesses access and leverage new advertising formats that can dramatically impact their success. Our solutions enable merchants to stay ahead of the curve and capitalize on the latest e-commerce innovations.',
    icon: RocketLaunchIcon,
  },
  {
    name: 'Customer Success',
    description: 'Our team of experts is committed to your success, providing dedicated support and continuous updates to ensure you get the most out of Meta\'s evolving advertising capabilities.',
    icon: UserGroupIcon,
  },
];

export default function About() {
  return (
    <div className="bg-primary-50">
      {/* Hero section */}
      <div className="relative isolate overflow-hidden bg-primary-600 py-24 sm:py-32">
        <img
          src="https://picsum.photos/1920/1080"
          alt="About hero background"
          className="absolute inset-0 -z-10 h-full w-full object-cover opacity-20"
        />
        <div className="absolute inset-0 bg-black/50" />
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl drop-shadow-lg animate-slide-up">About Straight2Cart</h1>
            <p className="mt-6 text-lg leading-8 text-white font-medium drop-shadow">
              A leading third-party integration provider specializing in advanced advertising solutions
            </p>
          </div>
        </div>
      </div>

      {/* Content section */}
      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900">Our Commitment</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Straight2Cart is an independent technology company specializing in advanced advertising integration solutions. We are committed to helping businesses leverage the full potential of modern advertising platforms through our innovative integration tools.
          </p>
          <div className="mt-4 text-lg leading-8 text-gray-600">
            <p className="font-semibold text-primary-600">Important Note:</p>
            <p>Straight2Cart is a third-party service provider focused on streamlining the link-based purchase experience.</p>
          </div>
        </div>

        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {values.map((value) => (
              <div key={value.name} className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-primary-600">
                    <value.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {value.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{value.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        {/* Impact section */}
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900">Business Impact</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Access to new Meta Ads formats can significantly impact your business performance. Our clients have experienced:
          </p>
          <ul className="mt-8 space-y-4 text-lg leading-8 text-gray-600">
            <li className="flex gap-x-3">
              <span className="text-primary-600">•</span> Increased conversion rates through optimized ad delivery
            </li>
            <li className="flex gap-x-3">
              <span className="text-primary-600">•</span> Enhanced customer engagement with new ad formats
            </li>
            <li className="flex gap-x-3">
              <span className="text-primary-600">•</span> Improved return on ad spend (ROAS)
            </li>
            <li className="flex gap-x-3">
              <span className="text-primary-600">•</span> Better audience targeting capabilities
            </li>
          </ul>
        </div>
      </div>

      {/* CTA section */}
      <div className="mx-auto mt-24 max-w-7xl sm:mt-32 sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-primary-700 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
          <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Ready to transform your Meta Ads performance?
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
            Join the growing number of businesses leveraging our integration solutions to maximize their advertising potential.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/contact"
              className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-primary-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Get started today
            </a>
          </div>
        </div>
      </div>

      <div className="mt-24 sm:mt-32"></div>
    </div>
  );
}
