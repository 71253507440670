import React from 'react';
import {
  ShoppingCartIcon,
  CreditCardIcon,
  CircleStackIcon,
  ArrowPathIcon,
  ChartBarIcon,
  CubeTransparentIcon,
  BoltIcon,
} from '@heroicons/react/24/outline';

const solutions = [
  {
    name: 'PrestaShop Integration',
    description:
      'Seamlessly connect your PrestaShop store with powerful advertising tools. Our integration ensures real-time product sync, automated catalog updates, and enhanced tracking capabilities.',
    icon: ShoppingCartIcon,
    features: [
      'Automated catalog sync with Meta Ads',
      'Real-time conversion tracking',
    ],
  },
  {
    name: 'OpenCart Solution',
    description:
      'Optimize your OpenCart store with our advanced Meta Ads integration. Get powerful tools for product management and campaign optimization.',
    icon: CubeTransparentIcon,
    features: [
      'Dynamic product feed integration',
      'Advanced pixel implementation',
    ],
  },
  {
    name: 'Stripe Integration',
    description:
      'Maximize your revenue with our Stripe payment integration. Track purchases, optimize conversion rates, and improve your ROAS with detailed analytics.',
    icon: CreditCardIcon,
    features: [
      'Purchase event tracking',
      'Revenue optimization tools',
    ],
  },
  {
    name: 'Adyen Solution',
    description:
      'Enterprise-grade payment processing with Adyen integration. Get comprehensive insights into your payment flow and customer behavior.',
    icon: CircleStackIcon,
    features: [
      'Enterprise-level tracking',
      'Multi-store conversion mapping',
    ],
  },
];

export default function Solutions() {
  return (
    <div className="bg-primary-50">
      {/* Hero section */}
      <div className="relative isolate overflow-hidden bg-primary-600">
        <img
          src="https://picsum.photos/1920/1080"
          alt="Solutions hero background"
          className="absolute inset-0 -z-10 h-full w-full object-cover opacity-20"
        />
        <div className="absolute inset-0 bg-black/70" />
        <div className="mx-auto max-w-7xl px-6 pb-12 pt-0 sm:pb-16 lg:flex lg:px-8 lg:pt-10 lg:pb-15">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0">
            <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl drop-shadow-lg animate-slide-up">
              Flexible Multi-Platform Support
            </h1>
            <p className="mt-6 text-lg leading-8 text-white font-medium drop-shadow">
              Straight2Cart meets sellers where they are; supporting a range of widely-used E-Commerce platforms.
            </p>
          </div>
        </div>
      </div>

      {/* Solutions section */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-12 sm:py-12">
        <div className="mx-auto mt-0 max-w-7xl">
          <div className="isolate mx-auto grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            {solutions.map((solution) => (
              <div
                key={solution.name}
                className="rounded-3xl p-8 ring-1 ring-primary-200 bg-gradient-to-br from-white to-primary-50 hover:shadow-lg transition-all duration-300 hover:scale-[1.02] xl:p-10"
              >
                <div className="flex items-center gap-x-3">
                  <solution.icon
                    className="h-7 w-7 flex-none text-primary-600"
                    aria-hidden="true"
                  />
                  <h3 className="text-lg font-semibold leading-8 text-primary-600">
                    {solution.name}
                  </h3>
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-600">{solution.description}</p>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                  {solution.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      {feature.includes('catalog') || feature.includes('feed') ? (
                        <CircleStackIcon 
                          className="h-6 w-5 flex-none text-primary-600"
                          aria-hidden="true"
                        />
                      ) : feature.includes('conversion') || feature.includes('tracking') ? (
                        <ChartBarIcon
                          className="h-6 w-5 flex-none text-primary-600"
                          aria-hidden="true"
                        />
                      ) : feature.includes('pixel') || feature.includes('event') ? (
                        <BoltIcon
                          className="h-6 w-5 flex-none text-primary-600"
                          aria-hidden="true"
                        />
                      ) : (
                        <ArrowPathIcon
                          className="h-6 w-5 flex-none text-primary-600"
                          aria-hidden="true"
                        />
                      )}
                      {feature}
                    </li>
                  ))}
                </ul>
                <a
                  href={solution.name === 'PrestaShop Integration' 
                    ? 'https://addons.prestashop.com/'
                    : solution.name === 'OpenCart Solution'
                    ? 'https://www.opencart.com/index.php?route=marketplace/extension'
                    : '/contact'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-8 block rounded-md bg-primary-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  {solution.name.includes('PrestaShop') || solution.name.includes('OpenCart') 
                    ? 'View in Marketplace' 
                    : 'Learn more'
                  }
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA section */}
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-primary-700 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
          <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Questions about our service?
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
            Get in touch with one of our certified experts today.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/contact"
              className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-primary-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Reach out
            </a>
          </div>
        </div>
      </div>

      <div className="mt-24 sm:mt-32"></div>
    </div>
  );
}
