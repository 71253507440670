import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon, ShoppingCartIcon, CubeIcon, BoltIcon } from '@heroicons/react/24/outline';

export default function Home() {
  return (
    <div className="flex-grow">
      {/* Hero Section */}
      <div className="relative h-[50vh]">
        <img
          src="https://picsum.photos/1920/1080"
          alt="Hero background"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-primary-900/90"></div>
        <div className="relative isolate px-6 pt-6 lg:px-8">
          <div className="h-[50vh] flex items-center justify-center">
            <div className="text-center max-w-2xl">
              <h1 className="animate-slide-up text-4xl font-extrabold tracking-tight text-white sm:text-6xl bg-clip-text">
                Send your customers straight to checkout
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-100 animate-fade-in-slow">
                Straight2Cart seamlessly integrates carts between social channels and E-Commerce Platforms, boosting your conversion rates
                and streamlining digital ads.
              </p>
              <div className="mt-10 flex items-center justify-center">
                <Link
                  to="/solutions#integration-paths"
                  className="rounded-full bg-primary-500 px-8 py-3.5 text-base font-semibold text-white shadow-lg hover:bg-primary-600 focus:ring-2 focus:ring-primary-400 transform transition-all duration-200 hover:scale-105 animate-pulse"
                >
                  Get Started Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-primary-50 py-16 sm:py-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-0 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Everything you need to manage your store
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {[
                {
                  icon: ShoppingCartIcon,
                  title: "Seamless Integration",
                  description: "Seamlessly connect your E-Commerce checkout to social media ads."
                },
                {
                  icon: BoltIcon,
                  title: "Lightning Fast",
                  description: "Install Straight2Cart in as little as one click."
                },
                {
                  icon: CubeIcon,
                  title: "World-class Support",
                  description: "Our team of experts are on-call to support customers 24/7"
                }
              ].map((feature) => (
                <div key={feature.title} className="flex flex-col bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] p-6">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <feature.icon className="h-7 w-7 flex-none text-primary-600" />
                    {feature.title}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-16 sm:px-6 sm:py-16 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gradient-to-br from-primary-600 to-primary-800 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
            <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Ready to boost your e-commerce business?
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
              Join thousands of successful PrestaShop merchants who trust Straight2Cart
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to="/solutions#integration-paths"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-primary-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                View Integration Options
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

{/* Features Section */ }
<div className="bg-gray-50 py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:text-center">
      <h2 className="text-base font-semibold leading-7 text-primary-600">Deploy faster</h2>
      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Everything you need to manage your store
      </p>
    </div>
    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
      <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
        <div className="flex flex-col">
          <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
            <ShoppingCartIcon className="h-5 w-5 flex-none text-primary-600" />
            Seamless Integration
          </dt>
          <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p className="flex-auto">Connect your E-Commerce store with modern e-commerce tools instantly.</p>
          </dd>
        </div>
        <div className="flex flex-col">
          <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
            <BoltIcon className="h-5 w-5 flex-none text-primary-600" />
            Lightning Fast
          </dt>
          <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p className="flex-auto">Experience rapid deployment and real-time synchronization.</p>
          </dd>
        </div>
        <div className="flex flex-col">
          <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
            <CubeIcon className="h-5 w-5 flex-none text-primary-600" />
            Scalable Solution
          </dt>
          <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p className="flex-auto">Grow your business with a solution that scales with your needs.</p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>

{/* CTA Section */ }
<div className="bg-white">
  <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
    <div className="relative isolate overflow-hidden bg-primary-700 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
      <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
        Ready to boost your e-commerce business?
      </h2>
      <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
        Join thousands of successful merchants who trust Straight2Cart
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <Link
          to="/contact"
          className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-primary-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
        >
          Get started today
        </Link>
      </div>
    </div>
  </div>
</div>

{/* Testimonials Section */ }
<div className="bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-xl text-center">
      <h2 className="text-lg font-semibold leading-8 tracking-tight text-accent-600">Testimonials</h2>
      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Trusted by PrestaShop Merchants
      </p>
    </div>
    <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
      <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
        {/* Testimonial 1 */}
        <div className="flex flex-col justify-between bg-white p-8 shadow-lg ring-1 ring-gray-900/5 rounded-2xl">
          <div>
            <p className="text-lg leading-6 text-gray-600">
              "Straight2Cart transformed our online store. The integration was seamless and our sales increased by 40%."
            </p>
          </div>
          <div className="mt-6 flex items-center gap-x-4">
            <div className="h-10 w-10 rounded-full bg-gray-100"></div>
            <div>
              <h3 className="text-sm font-semibold leading-6 text-gray-900">Sarah Johnson</h3>
              <p className="text-sm leading-6 text-gray-500">CEO, Fashion Boutique</p>
            </div>
          </div>
        </div>

        {/* Testimonial 2 */}
        <div className="flex flex-col justify-between bg-white p-8 shadow-lg ring-1 ring-gray-900/5 rounded-2xl">
          <div>
            <p className="text-lg leading-6 text-gray-600">
              "The best decision we made was switching to Straight2Cart. Their support team is exceptional."
            </p>
          </div>
          <div className="mt-6 flex items-center gap-x-4">
            <div className="h-10 w-10 rounded-full bg-gray-100"></div>
            <div>
              <h3 className="text-sm font-semibold leading-6 text-gray-900">Michael Chen</h3>
              <p className="text-sm leading-6 text-gray-500">Owner, Tech Store</p>
            </div>
          </div>
        </div>

        {/* Testimonial 3 */}
        <div className="flex flex-col justify-between bg-white p-8 shadow-lg ring-1 ring-gray-900/5 rounded-2xl">
          <div>
            <p className="text-lg leading-6 text-gray-600">
              "Integration was quick and the results were immediate. Highly recommend!"
            </p>
          </div>
          <div className="mt-6 flex items-center gap-x-4">
            <div className="h-10 w-10 rounded-full bg-gray-100"></div>
            <div>
              <h3 className="text-sm font-semibold leading-6 text-gray-900">Emma Davis</h3>
              <p className="text-sm leading-6 text-gray-500">Director, Home Goods</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{/* Stats Section */ }
<div className="bg-primary-600 py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:max-w-none">
      <div className="text-center">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Trusted by merchants worldwide
        </h2>
        <p className="mt-4 text-lg leading-8 text-primary-100">
          We've helped thousands of PrestaShop stores grow their business
        </p>
      </div>
      <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
        <div className="flex flex-col bg-white/5 p-8">
          <dt className="text-sm font-semibold leading-6 text-primary-100">Active merchants</dt>
          <dd className="order-first text-3xl font-semibold tracking-tight text-white">8,000+</dd>
        </div>
        <div className="flex flex-col bg-white/5 p-8">
          <dt className="text-sm font-semibold leading-6 text-primary-100">Orders processed</dt>
          <dd className="order-first text-3xl font-semibold tracking-tight text-white">1M+</dd>
        </div>
        <div className="flex flex-col bg-white/5 p-8">
          <dt className="text-sm font-semibold leading-6 text-primary-100">Integration success rate</dt>
          <dd className="order-first text-3xl font-semibold tracking-tight text-white">99.9%</dd>
        </div>
        <div className="flex flex-col bg-white/5 p-8">
          <dt className="text-sm font-semibold leading-6 text-primary-100">Customer satisfaction</dt>
          <dd className="order-first text-3xl font-semibold tracking-tight text-white">4.9/5</dd>
        </div>
      </dl>
    </div>
  </div>
</div>

{/* Partners Section */ }
<div className="bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:text-center">
      <h2 className="text-lg font-semibold leading-8 tracking-tight text-secondary-600">Trusted Partners</h2>
      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Working with the best in e-commerce
      </p>
    </div>
    <div className="mx-auto mt-16 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
      {[...Array(5)].map((_, i) => (
        <div key={i} className="h-12 w-full bg-gray-200 rounded"></div>
      ))}
    </div>
  </div>
</div>


const Testy = <>
  {/* Testimonials Section */}
  <div className="bg-gray-50 py-24 sm:py-32">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-xl text-center">
        <h2 className="text-lg font-semibold leading-8 tracking-tight text-accent-600">Testimonials</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Trusted by PrestaShop Merchants
        </p>
      </div>
      <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {/* Testimonial 1 */}
          <div className="flex flex-col justify-between bg-white p-8 shadow-lg ring-1 ring-gray-900/5 rounded-2xl">
            <div>
              <p className="text-lg leading-6 text-gray-600">
                "Straight2Cart transformed our online store. The integration was seamless and our sales increased by 40%."
              </p>
            </div>
            <div className="mt-6 flex items-center gap-x-4">
              <div className="h-10 w-10 rounded-full bg-gray-100"></div>
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Sarah Johnson</h3>
                <p className="text-sm leading-6 text-gray-500">CEO, Fashion Boutique</p>
              </div>
            </div>
          </div>

          {/* Testimonial 2 */}
          <div className="flex flex-col justify-between bg-white p-8 shadow-lg ring-1 ring-gray-900/5 rounded-2xl">
            <div>
              <p className="text-lg leading-6 text-gray-600">
                "The best decision we made was switching to Straight2Cart. Their support team is exceptional."
              </p>
            </div>
            <div className="mt-6 flex items-center gap-x-4">
              <div className="h-10 w-10 rounded-full bg-gray-100"></div>
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Michael Chen</h3>
                <p className="text-sm leading-6 text-gray-500">Owner, Tech Store</p>
              </div>
            </div>
          </div>

          {/* Testimonial 3 */}
          <div className="flex flex-col justify-between bg-white p-8 shadow-lg ring-1 ring-gray-900/5 rounded-2xl">
            <div>
              <p className="text-lg leading-6 text-gray-600">
                "Integration was quick and the results were immediate. Highly recommend!"
              </p>
            </div>
            <div className="mt-6 flex items-center gap-x-4">
              <div className="h-10 w-10 rounded-full bg-gray-100"></div>
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Emma Davis</h3>
                <p className="text-sm leading-6 text-gray-500">Director, Home Goods</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  {/* Stats Section */}
  <div className="bg-gradient-to-br from-primary-700 to-primary-900 py-24 sm:py-32">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl lg:max-w-none">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Trusted by merchants worldwide
          </h2>
          <p className="mt-4 text-lg leading-8 text-primary-100">
            We've helped thousands of PrestaShop stores grow their business
          </p>
        </div>
        <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col bg-white/5 p-8">
            <dt className="text-sm font-semibold leading-6 text-primary-100">Active merchants</dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-white">8,000+</dd>
          </div>
          <div className="flex flex-col bg-white/5 p-8">
            <dt className="text-sm font-semibold leading-6 text-primary-100">Orders processed</dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-white">1M+</dd>
          </div>
          <div className="flex flex-col bg-white/5 p-8">
            <dt className="text-sm font-semibold leading-6 text-primary-100">Integration success rate</dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-white">99.9%</dd>
          </div>
          <div className="flex flex-col bg-white/5 p-8">
            <dt className="text-sm font-semibold leading-6 text-primary-100">Customer satisfaction</dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-white">4.9/5</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>

  {/* Partners Section */}
  <div className="bg-white py-24 sm:py-32">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl lg:text-center">
        <h2 className="text-lg font-semibold leading-8 tracking-tight text-secondary-600">Trusted Partners</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Working with the best in e-commerce
        </p>
      </div>
      <div className="mx-auto mt-16 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
        {[...Array(5)].map((_, i) => (
          <div key={i} className="h-12 w-full bg-gray-200 rounded"></div>
        ))}
      </div>
    </div>
  </div></>
