import React from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';

const platforms = [
  {
    name: 'PrestaShop',
    price: '100',
    supportPrice: '40',
    description: 'Complete Meta Ads integration for PrestaShop',
    features: [
      'One-time purchase',
      'Full Meta Ads integration',
      'Product catalog sync',
      'Conversion tracking',
      'Basic support included',
      'Optional premium support',
    ],
  },
  {
    name: 'OpenCart',
    price: '120',
    supportPrice: '45',
    description: 'Enhanced Meta Ads solution for OpenCart',
    features: [
      'One-time purchase',
      'Advanced catalog management',
      'Real-time sync',
      'Performance analytics',
      'Basic support included',
      'Optional premium support',
    ],
  },
  {
    name: 'Enterprise',
    price: 'Custom',
    supportPrice: 'Included',
    description: 'Custom solutions for large businesses',
    features: [
      'Custom integration',
      'Multiple store support',
      'Priority support',
      'Dedicated account manager',
      'Custom features',
      'SLA guarantee',
    ],
  },
];

export default function Pricing() {
  return (
    <div className="bg-primary-50">
      <div className="px-4 pt-12 sm:px-6 lg:px-8 lg:pt-16">
        <div className="text-center">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl animate-slide-up">
            Simple, transparent pricing
          </h1>
          <p className="mx-auto mt-4 max-w-2xl text-xl text-gray-500">
            Choose the perfect plan for your e-commerce business
          </p>
        </div>

        <div className="mx-auto mt-16 max-w-7xl pb-16">
          <div className="isolate mx-auto grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {platforms.map((platform) => (
              <div
                key={platform.name}
                className="rounded-3xl p-8 ring-1 ring-primary-200 bg-gradient-to-br from-white to-primary-50 hover:shadow-lg transition-all duration-300 hover:scale-[1.02] xl:p-10"
              >
                <h3 className="text-lg font-semibold leading-8 text-primary-600">
                  {platform.name}
                </h3>
                <p className="mt-4 text-sm leading-6 text-gray-600">
                  {platform.description}
                </p>
                <div className="mt-6 flex items-baseline gap-x-2">
                  {platform.price === 'Custom' ? (
                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                      Custom
                    </span>
                  ) : (
                    <>
                      <span className="text-4xl font-bold tracking-tight text-gray-900">
                        ${platform.price}
                      </span>
                      <span className="text-sm font-semibold leading-6 text-gray-600">
                        one-time
                      </span>
                    </>
                  )}
                </div>
                {platform.supportPrice !== 'Included' && (
                  <p className="mt-2 text-sm text-gray-500">
                    + ${platform.supportPrice}/year for premium support
                  </p>
                )}
                <ul
                  role="list"
                  className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
                >
                  {platform.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-primary-600"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
                <a
                  href="/contact"
                  className="mt-8 block rounded-md bg-primary-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  Get started
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10 pb-24">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            <div className="pt-6">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                What's included in the premium support?
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                Premium support includes priority email support, faster response times, 
                and dedicated assistance with technical issues. You'll also get access 
                to new features before they're publicly released.
              </dd>
            </div>
            <div className="pt-6">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                Can I upgrade to premium support later?
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                Yes, you can upgrade to premium support at any time. The annual fee 
                will be prorated based on when you upgrade.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
